import Group from "@/components/atoms/Group.tsx";
import Stack from "@/components/atoms/Stack.tsx";
import Text from "@/components/atoms/text/Text.tsx";
import Link from "@/components/atoms/Link.tsx";

const Footer = () => {
  return (
    <div className={"py-8 mt-8 w-full bg-primary px-4"}>
      <div className={"max-w-6xl mx-auto flex text-white"}>
        <Group
          align={"center"}
          justify={"around"}
          w={"full"}
          className={"flex-wrap md:flex-nowrap gap-6"}
        >
          <Stack gap={"lg"}>
            <img
              width={575}
              height={44}
              loading={"lazy"}
              className={"object-contain h-6 w-auto"}
              alt={"Mobilabonnement logo png"}
              src={"/logo_light.png"}
            />
            <Stack gap={"xs"}>
              <Text variant={"subtleLight"} className={"font-semibold"}>
                Sammenlign mobilabonnement i Norge
              </Text>
              <Text
                size={"xs"}
                variant={"subtleLight"}
                className={"font-semibold"}
              >
                Copyright Bedriftsabonnement AS - 2024
              </Text>
            </Stack>
          </Stack>
          <Group align={"start"} w={"full"}>
            <Stack w={"full"} gap={"sm"}>
              <span
                className={"scroll-m-20 text-xl font-semibold tracking-tight"}
              >
                Lenker
              </span>
              <Link href={"/"} title={"Hjem"} />
              <Link href={"/bedrift"} title={"Bedrift"} />
              <Link href={"/artikler"} title={"Artikler"} />
              <Link href={"/om-oss"} title={"Om oss"} />
              <Link href={"/mobilselskaper"} title={"Mobilselskaper"} />
              <Link href={"/cookie-og-personvern"} title={"Personvern"} />
            </Stack>
            <Stack w={"full"} gap={"sm"}>
              <span
                className={"scroll-m-20 text-xl font-semibold tracking-tight"}
              >
                Mobilabonnement
              </span>
              <Link href={"/bedrift"} title={"Mobilabonnement for bedrift"} />
              <Link
                href={"/mobilabonnement-for-barn"}
                title={"Mobilabonnement for barn"}
              />
              <Link
                href={"/mobilabonnement-for-studenter"}
                title={"Mobilabonnement for studenter"}
              />
              <Link
                href={"/mobilabonnement-for-unge"}
                title={"Mobilabonnement for unge under 30"}
              />
              <Link
                href={"/mobilabonnement-for-eldre"}
                title={"Mobilabonnement for eldre"}
              />
              <Link
                href={"/familieabonnement"}
                title={"Mobilabonnement for familie"}
              />
              <Link
                href={"/mobilabonnement-med-fri-data"}
                title={"Mobilabonnement med fri data"}
              />
            </Stack>
          </Group>
        </Group>
      </div>
    </div>
  );
};

export default Footer;
