import type { AnchorHTMLAttributes } from "react";

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  title: string;
}

const Link = ({ title, ...props }: LinkProps) => {
  return (
    <a
      {...props}
      className={
        "text-gray-100 text-md hover:text-gray-300 transition-colors duration-200"
      }
    >
      {title}
    </a>
  );
};

export default Link;
